import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import RequestsList from './components/RequestsList';
import { useAgencyRequests } from 'contexts/AgencyRequestsContext';
import { useNavigate, useParams } from 'react-router-dom';
import AgenciesPage from './components/AgenciesPage';
import LotusPage from 'components/widgets/Layout/LotusPage';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAuthState } from 'contexts/AuthProvider';

export default function AgencyManagement() {
  const [tabIndex, setTabIndex] = useState(0);
  const { page } = useParams();
  const navigate = useNavigate();

  const { verifyPermission } = useAuthState();
  const hasApprovePermission = verifyPermission(PERMISSIONS.APPROVE_MODIFY_AGENCY) || verifyPermission(PERMISSIONS.APPROVE_SUB_AGENCY);

  const { pendingRequests, loadPendingAgencyRequests } = useAgencyRequests();

  useEffect(() => {
    if (page) {
      switch (page) {
        case 'list': {
          setTabIndex(0);
          break;
        }
        case 'requests': {
          setTabIndex(1);
          break;
        }
        default: {
          setTabIndex(0);
          break;
        }
      }
    }
  }, [page]);

  const handleTabChange = async (event, newValue) => {
    switch (newValue) {
      case 0:
        await navigate('/agencies/list');
        return;
      case 1:
        await navigate('/agencies/requests');
        return;
      default:
    }
  };

  // sigh, always need to get requests right away to display the badge
  useEffect(() => {
    if (hasApprovePermission) {
      loadPendingAgencyRequests();
    }
  }, []);

  return (
    <LotusPage>
      <LotusSpacedBlock>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab
            label={
              <Typography variant="body2" color="textPrimary">Agency List</Typography>
            }
          />
          {hasApprovePermission &&
            <Tab
              label={
                <Typography variant="body2" color="textPrimary">
                  Approval Requests
                  <Badge
                    style={{
                      top: -10,
                      right: -10,
                      marginRight: 20
                    }}
                    badgeContent={
                      pendingRequests && pendingRequests.length
                    }
                    overlap="circular"
                    color="primary"
                  />
                </Typography>
              }
            />
          }
        </Tabs>
      </LotusSpacedBlock>
      {tabIndex === 0 && <AgenciesPage />}
      {tabIndex === 1 && (
        <RequestsList requests={pendingRequests} />
      )}
    </LotusPage>
  );
}
