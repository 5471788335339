import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuthState } from './contexts/AuthProvider';
import Layout from 'components/Layout';
import Home from './pages/index';
import Dashboards from './pages/dashboards';
import Dashboard from './pages/dashboards/dashboard';
import AgencyManagement from './pages/agencyManagement';
import ClientManagement from './pages/clientManagement';
import PermissionTemplateManagement from './pages/permissionTemplateManagement';
import ProgramManagement from './pages/programManagement';
import UserManagement from './pages/userManagement';
import MyAccount from './pages/myAccount';
import ClientPage from './pages/ClientPage';
import FormManagement from './pages/formManagement';
import ImportExportManagement from './pages/importsExports';
import { ClientContextProvider } from './contexts/ClientContext';
import { ClientSearchProvider } from './contexts/ClientSearchContext';
import { DynamicFormProvider } from './contexts/DynamicFormContext';
import { UserManagementProvider, useUserManagement } from './contexts/UserManagementContext';
import { DashboardsProvider } from './contexts/DashboardsContext';
import { EnrollmentsProvider } from 'contexts/EnrollmentsContext';
import { ClientDocumentationProvider } from 'contexts/ClientDocumentationContext';
import { ClientCarePlanProvider } from 'contexts/ClientCarePlanContext';
import { EligibilityApplicationProvider } from 'contexts/EligibilityApplicationContext';
import { EligibilityTimelineProvider } from 'contexts/EligibilityTimelineContext';
import EditEligibilityApplication from 'pages/ClientPage/components/Eligibility/EditEligibilityApplication';
import Applications from 'pages/eligibilityApplications';
import { EligibilityApplicationSearchProvider } from 'contexts/EligibilityApplicationSearchContext';
import { UserSearchProvider } from 'contexts/UserSearchContext';
import { AgencyManagementProvider } from 'contexts/AgencyManagementContext';
import { AgencyIntegrationProvider } from 'contexts/AgencyIntegrationsContext';
import { AgencySearchProvider } from 'contexts/AgencySearchContext';
import { AgencyRequestProvider } from 'contexts/AgencyRequestsContext';
import { PermissionTemplateManagementProvider } from 'contexts/PermissionTemplateManagementContext';
import { PermissionTemplateSearchProvider } from 'contexts/PermissionTemplateSearchContext';
import { PermissionTemplateRequestProvider } from 'contexts/PermissionTemplateRequestsContext';
import { UserRequestProvider } from 'contexts/UserRequestsContext';
import LoginPage from './pages/authentication/LoginPage';
import Container from '@mui/material/Container';
import { ClientFormContextProvider } from 'contexts/ClientFormContext';
import { HealthPlansProvider } from 'contexts/HealthPlansContext';
import { AgencyProvider } from 'contexts/AgencyContext';
import { ProgramManagementProvider } from 'contexts/ProgramManagementContext';
import Training from 'pages/training';
import LayoutWithoutNav from 'components/LayoutWithoutNav';
import { FilesProvider } from 'contexts/FilesContext';

const PageWrapper = ({ children }) => {
  return <Layout>{children}</Layout>;
};

const PageWrapperWithoutNav = ({ children }) => {
  return <LayoutWithoutNav>{children}</LayoutWithoutNav>;
};

const Authenticated = ({ children }) => {
  const location = useLocation();
  const { user } = useAuthState();

  if (user) {
    return children;
  } else {
    const path = location.pathname;
    if (path.length > 0 && !['/', '/index.html'].includes(path)) {
      Cookies.set('requested_url', path);

      return (
        <>
          <LoginPage />
        </>
      );
    }

    return children;
  }
};

const PageRouter = (props) => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <PageWrapper>
              <Home />
            </PageWrapper>
          }
        />

        <Route
          path="/dashboard/:type"
          element={
            <Authenticated>
              <DashboardsProvider>
                <EligibilityApplicationProvider>
                  <PageWrapper>
                    <Dashboard />
                  </PageWrapper>
                </EligibilityApplicationProvider>
              </DashboardsProvider>
            </Authenticated>
          }
        />

        <Route
          path="/dashboards"
          element={
            <Authenticated>
              <DashboardsProvider>
                <PageWrapper>
                  <Dashboards />
                </PageWrapper>
              </DashboardsProvider>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/agencies/:page/:id?"
          element={
            <Authenticated>
              <PageWrapper>
                <DynamicFormProvider>
                  <DashboardsProvider>
                    <AgencyRequestProvider>
                      <AgencyManagementProvider>
                        <AgencyIntegrationProvider>
                          <AgencySearchProvider>
                            <UserRequestProvider>
                              <AgencyManagement />
                            </UserRequestProvider>
                          </AgencySearchProvider>
                        </AgencyIntegrationProvider>
                      </AgencyManagementProvider>
                    </AgencyRequestProvider>
                  </DashboardsProvider>
                </DynamicFormProvider>
              </PageWrapper>
            </Authenticated>
          }
        />

        {/* Eligibility app doesnt show inside the client page for whatever reason, <shrug> */}
        <Route
          exact
          path="/client/:clientId/eligibility/application/:id/:tab?"
          element={
            <Authenticated>
              <ClientContextProvider>
                <PageWrapper>
                  <EnrollmentsProvider>
                    <HealthPlansProvider>
                      <EligibilityApplicationProvider>
                        <EditEligibilityApplication />
                      </EligibilityApplicationProvider>
                    </HealthPlansProvider>
                  </EnrollmentsProvider>
                </PageWrapper>
              </ClientContextProvider>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/client/:clientId/:mainTab/:lvl2?/:lvl3?/:lvl4?/:lvl5?/:lvl6?" // structure of underneath pages isnt consistent
          element={
            <Authenticated>
              <AgencyProvider>
                <AgencySearchProvider>
                  <ClientContextProvider>
                    <PageWrapper>
                      <EnrollmentsProvider>
                        <ClientCarePlanProvider>
                          <HealthPlansProvider>
                            <ClientDocumentationProvider>
                              <EligibilityApplicationProvider>
                                <EligibilityTimelineProvider>
                                  <ClientPage />
                                </EligibilityTimelineProvider>
                              </EligibilityApplicationProvider>
                            </ClientDocumentationProvider>
                          </HealthPlansProvider>
                        </ClientCarePlanProvider>
                      </EnrollmentsProvider>
                    </PageWrapper>
                  </ClientContextProvider>
                </AgencySearchProvider>
              </AgencyProvider>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/clients/:page/:view?"
          element={
            <Authenticated>
              <AgencyProvider>
                <AgencySearchProvider>
                  <ClientSearchProvider>
                    <PageWrapper>
                      <ClientManagement />
                    </PageWrapper>
                  </ClientSearchProvider>
                </AgencySearchProvider>
              </AgencyProvider>
            </Authenticated>
          }
        />
        
        <Route
          path="/imports/:page/:view?/:subview?"
          element={
            <Authenticated>
              <PageWrapper>
                <ImportExportManagement />
              </PageWrapper>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/applications/:page/:view?"
          element={
            <Authenticated>
              <AgencySearchProvider>
                <EligibilityApplicationProvider>
                <EligibilityApplicationSearchProvider>
                  <PageWrapper>
                    <Applications />
                  </PageWrapper>
                </EligibilityApplicationSearchProvider>
                </EligibilityApplicationProvider>
              </AgencySearchProvider>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/users/:page/:id?"
          element={
            <Authenticated>
              <UserSearchProvider>
                <AgencySearchProvider>
                  <AgencyManagementProvider>
                    <UserRequestProvider>
                      <UserManagementProvider>
                        <UserSearchProvider>
                          <PageWrapper>
                            <UserManagement />
                          </PageWrapper>
                        </UserSearchProvider>
                      </UserManagementProvider>
                    </UserRequestProvider>
                  </AgencyManagementProvider>
                </AgencySearchProvider>
              </UserSearchProvider>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/templates/:page/:id?"
          element={
            <Authenticated>
              <PermissionTemplateRequestProvider>
                <PermissionTemplateManagementProvider>
                  <PermissionTemplateSearchProvider>
                    <AgencySearchProvider>
                      <PageWrapper>
                        <PermissionTemplateManagement />
                      </PageWrapper>
                    </AgencySearchProvider>
                  </PermissionTemplateSearchProvider>
                </PermissionTemplateManagementProvider>
              </PermissionTemplateRequestProvider>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/programs/:page/:action?"
          element={
            <Authenticated>
              <PageWrapper>
                <ClientCarePlanProvider>
                  <DynamicFormProvider>
                    <AgencySearchProvider>
                      <ProgramManagementProvider>
                        <ProgramManagement />
                      </ProgramManagementProvider>
                    </AgencySearchProvider>
                  </DynamicFormProvider>
                </ClientCarePlanProvider>
              </PageWrapper>
            </Authenticated>
          }
        />

        <Route
          exact
          path="/forms/:page/:id?"
          element={
            <Authenticated>
              <PageWrapper>
                <DynamicFormProvider>
                  <ClientFormContextProvider>
                    <FormManagement />
                  </ClientFormContextProvider>
                </DynamicFormProvider>
              </PageWrapper>
            </Authenticated>
          }
        />

        <Route
          path="/myAccount"
          element={
            <Authenticated>
              <PageWrapper>
                <MyAccount />
              </PageWrapper>
            </Authenticated>
          }
        />
        <Route
          path="/training"
          element={
            <Authenticated>
              <PageWrapperWithoutNav>
                <FilesProvider>
                  <Training />
                </FilesProvider>
              </PageWrapperWithoutNav>
            </Authenticated>
          }
        />
      </Routes>
    </>
  );
};

export default PageRouter;
