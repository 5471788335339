import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { Alert, Grid, Stack } from '@mui/material';
import EligibilityApplicationRyanWhiteStatus from './EligibilityApplicationRyanWhiteStatus';
import moment from 'moment';
import { useLists } from 'contexts/ListsContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { userLeftUnresolvedComment } from './common';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from "lib/permissionEnums";
import uniq from "lodash/uniq";
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import SectionCommentsChain from './SectionCommentsChain';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { useClient } from 'contexts/ClientContext';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export const getServicesStaticFormSchema = (eligibilityAppConfig, loggedInUser, isAdapProcessor, grandfatherStatus) => {
  return Yup.object().shape({
    wantsToApplyForAssistance: Yup.bool().required("Does applicant wish to apply for insurance and medication assistance? is required"),
    subprogramIds: Yup.array().test({
      name: 'valid', 
      skipAbsent: false,
      test(value, ctx) {
        if (eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && ctx.parent.wantsToApplyForAssistance && value.length === 0) {
          return ctx.createError({message: 'At least one subprogram must be selected'});
        }
        return true;
      }
    }),
    subprogramId: Yup.string().test({
      name: 'valid', 
      skipAbsent: false,
      test(value, ctx) {
        if (!eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && ctx.parent.wantsToApplyForAssistance && !value) {
          return ctx.createError({message: 'One subprogram must be selected'});
        }
        return true;
      }
    }),
    subprogramCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
    areServicesAuthorized: Yup.bool().nullable().test({
      name: 'specifiedIfEligible',
      skipAbsent: false,
      test(value, ctx) {
        if (userLeftUnresolvedComment(ctx.parent, loggedInUser) || grandfatherStatus) {
          return true; // not making this required if commenting
        }
        if (isAdapProcessor && ctx.parent.wantsToApplyForAssistance === true && ctx.parent.rwEligible && value === null) {
          return ctx.createError({ message: `Do you wish to authorize this client for ${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} services? is required`});
        }
        return true;
      }
    }),
    servicesNotAuthorizedReasonId: Yup.string().nullable().test({
      name: 'present',
      skipAbsent: false,
      test(value, ctx) {
        if (isAdapProcessor && ctx.parent.wantsToApplyForAssistance === true && ctx.parent.areServicesAuthorized === false && !value && !grandfatherStatus) {
          return ctx.createError({ message: 'Reason for Opting Out is required' });
        }
        return true;
      }
    }),
    decisionEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        if (isAdapProcessor && ctx.parent.rwEligible && !value && !grandfatherStatus) {
          return ctx.createError({ message: 'Effective Date is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Effective Date must be a valid date' });
          }
          if (toValidate < moment().startOf('day')) {
            return ctx.createError({ message: 'Effective Date cannot be before today' });
          }
        }
        return true;
      }
    }),
  });
}

export const getServicesStaticFormSchemaExcludingRequiredChecks = (eligibilityAppConfig) => {
  return Yup.object().shape({
    decisionEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Effective Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Effective Date is too old' });
          }
        }
        return true;
      }
    }),
  });
}

export const buildServicesStaticFormSectionNames = (eligibilityAppConfig) => { 
  let lst = [
    "Ryan White Eligibility",
    `${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} Enrollment`
  ];
  return lst;
}

export const getServicesFormData = (formData, originalValues) => {

  // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
  const {
    areServicesAuthorized,
    servicesNotAuthorizedReasonId,
    decisionEffectiveDate
  } = formData;

  const serviceInfo = {
    areServicesAuthorized,
    servicesNotAuthorizedReasonId,
    decisionEffectiveDate
  };
  return serviceInfo;
}

export const getSubprogramsFormData = (formData, originalValues, eligibilityAppConfig) => {

  // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
  const {
    subprogramIds,
    subprogramId,
    wantsToApplyForAssistance,
    subprogramCommentsChain,
    rwEligible,
    rwHivStatusIneligible,
    rwIncomeIneligible,
    rwResidencyIneligible
  } = formData;

  let subprogramList = [];
  if(wantsToApplyForAssistance && eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments) {
    subprogramList = subprogramIds.map(id => originalValues.subprogramList.find(v => v.agencyAdapSubprogramId === id) || { agencyAdapSubprogramId: id });
  } else if (wantsToApplyForAssistance && !eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments) {
    subprogramList = subprogramId ? [originalValues.subprogramList.find(v => v.agencyAdapSubprogramId === subprogramId) || { agencyAdapSubprogramId: subprogramId } ] : [];
  }

  const subprogramInfo = {
    subprogramList: subprogramList,
    subprogramCommentsChain,
    rwEligible,
    rwHivStatusIneligible,
    rwIncomeIneligible,
    rwResidencyIneligible
  };
  return subprogramInfo;
}

export const initializeServicesFormData = (formData, meta) => {

  if (!formData) {
    return formData;
  }

  const lst = formData.subprogramList.map(s => { 
    return s.agencyAdapSubprogramId
  });

  const result = {
    areServicesAuthorized: formData.areServicesAuthorized !== null ? formData.areServicesAuthorized : null,
    servicesNotAuthorizedReasonId: formData.servicesNotAuthorizedReasonId || '',
    decisionEffectiveDate: formData.decisionEffectiveDate || null,

    wantsToApplyForAssistance: lst.length > 0 || meta.isAdapOnlyApplication || false,
    subprogramIds: lst,
    subprogramId: lst && lst.length > 0 ? lst[0].toString() : '',
    subprogramCommentsChain: formData.subprogramCommentsChain || [],
    rwEligible: meta.rwEligible !== null ? meta.rwEligible : true,
    rwHivStatusIneligible: meta.rwHivStatusIneligible !== null ? meta.rwHivStatusIneligible : true,
    rwIncomeIneligible: meta.rwIncomeIneligible !== null ? meta.rwIncomeIneligible : false,
    rwResidencyIneligible: meta.rwResidencyIneligible !== null ? meta.rwResidencyIneligible : true,

    servicesNeedRecalc: true
  };

  return result;
}


const EligibilityApplicationServicesStaticForm = ({existingClientConfig, eligibilityAppConfig, disabled, clientId, isInterimUpdate}) => {
 
  const { values, setFieldValue, validateForm } = useFormikContext();
  const { verifyPermission } = useAuthState();
  const isAdapProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR);
  const { userAgency, userAgencyAdapSubprograms, loadUserAgencyAdapSubprograms} = useUserAgency();

  const { privateInsuranceStatuses, privateInsuranceSources, medicationAssistanceLevels,
    premiumPaymentFrequencies, medicareStatuses, adapServiceDenialReasons, premiumAssistanceCoverageTypes } = useLists();

  const [adapServiceDenialReasonList, setAdapServiceDenialReasonList] = useState();
  const [agencyAdapSubprogramList, setAgencyAdapSubprogramList] = useState();
  const { adapProgramEnrollments, loadAdapEnrollmentsForClient } = useEnrollments();
  const [currentAdapEnrollment, setCurrentAdapEnrollment] = useState();
  const [futureAdapEnrollments, setFutureAdapEnrollments] = useState();

  useEffect(() => {
    if (clientId) {
      // need to check whether client is currently enrolled in adap, can enroll during client update if not already enrolled
      loadAdapEnrollmentsForClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (adapProgramEnrollments) {
      let now = moment().startOf('day');
      let curr = adapProgramEnrollments.find(e => moment(e.startDate,'MM/DD/YYYY') <= now && moment(e.endDate,'MM/DD/YYYY') >= now);
      if (curr) {
        let currentEnrollmentToDisplay = {
          startDate: curr.startDate,
          endDate: curr.endDate,
          subprogramNames: curr.subprograms.map(s => s.name).join(", "),
          medicationAssistanceEnabled: curr.medicationAssistanceEnabled,
          medicationAssistanceLevelText: medicationAssistanceLevels.find(l => curr.medicationAssistanceLevel === l.itemKey)?.itemName,
          premiumAssistanceEnabled: curr.premiumAssistanceEnabled,
          premiumAssistanceCoverageType: premiumAssistanceCoverageTypes.find(t => curr.premiumAssistanceCoverageType === t.itemKey)?.itemName,
          authorizedAdapPremiumAmount: curr.authorizedAdapPremiumAmount,
          authorizedAdapPremiumFrequencyName: premiumPaymentFrequencies.find(f => curr.authorizedAdapPremiumFrequencyId === f.id)?.itemName,
          authorizedAdapInsuranceStartDate: curr.authorizedAdapInsuranceStartDate,
          authorizedAdapInsuranceCarrier: curr.authorizedAdapInsuranceCarrier,
          authorizedAdapInsurancePlan: curr.authorizedAdapInsurancePlan,
          authorizedAdapInsurancePolicySource: privateInsuranceSources.find(f => curr.authorizedAdapInsurancePolicySourceId === f.id)?.itemName,
        };
        setCurrentAdapEnrollment(currentEnrollmentToDisplay);
        setFieldValue('currentAdapEnrollment', currentEnrollmentToDisplay);
      }
      let future = adapProgramEnrollments.filter(e => moment(e.startDate,'MM/DD/YYYY') > now);
      setFutureAdapEnrollments(future);
    }
  }, [adapProgramEnrollments]);

  useEffect(() => {
    if (eligibilityAppConfig && adapServiceDenialReasons) {
      const types = adapServiceDenialReasons.filter(
          t => t.id === values.servicesNotAuthorizedReasonId || (eligibilityAppConfig.selectedAdapServiceDenialReasons && eligibilityAppConfig.selectedAdapServiceDenialReasons.includes(t.id)))
        .map(t => { return {label: t.itemName, value: t.id}});
      setAdapServiceDenialReasonList(types);
    }
  }, [eligibilityAppConfig, adapServiceDenialReasons, values.servicesNotAuthorizedReasonId]);

  useEffect(() => {
    if (!userAgencyAdapSubprograms) {
      loadUserAgencyAdapSubprograms();
    } else if (values.subprogramIds) {
      setAgencyAdapSubprogramList(userAgencyAdapSubprograms.filter(s => s.active || values.subprogramIds.includes(s.id)).map(s => { return {label: s.name, value: s.id}}));
    }
  }, [userAgency, userAgencyAdapSubprograms, values.subprogramIds]);

  const recalcServices = () => {
    const getSupportedServicesForSelectedSubprograms = (subprogramIds) => {
      let medicationAssistanceEnabled = false;
      let premiumAssistanceEnabled = false;
      let medicationAssistanceLevel = null;
      let medicationAssistanceLevelText = '';
      let premiumAssistanceCoverageTypes = [];
    
      subprogramIds.sort().forEach(id => { // sort just guarantees consistent result
        let subprogram = eligibilityAppConfig.adapSubprograms.find(s => s.id === id);
        medicationAssistanceEnabled = medicationAssistanceEnabled || subprogram.supportsMedicationAssistance;
        if (subprogram.supportsMedicationAssistance) {
          medicationAssistanceLevel = subprogram.medicationAssistanceLevel;
          medicationAssistanceLevelText = medicationAssistanceLevels.find(lvl => lvl.itemKey === subprogram.medicationAssistanceLevel)?.itemName;
        }
        premiumAssistanceEnabled = premiumAssistanceEnabled || subprogram.supportsPremiumAssistance;
        if (subprogram.supportsPremiumAssistance) {
          premiumAssistanceCoverageTypes.push(...subprogram.premiumAssistanceCoverageTypes);
        }
      });
    
      return {
        medicationAssistanceEnabled,
        premiumAssistanceEnabled,
        medicationAssistanceLevel,
        medicationAssistanceLevelText,
        premiumAssistanceCoverageTypes: uniq(premiumAssistanceCoverageTypes.sort())
      };
    }
  
    let supportedServices = getSupportedServicesForSelectedSubprograms(
      eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && values.areServicesAuthorized ? values.subprogramIds : (values.subprogramId ? values.subprogramId.split() : []), 
      eligibilityAppConfig);

    if (supportedServices.premiumAssistanceCoverageTypes.length > 0) {
      let foundCoveredInsurance = supportedServices.premiumAssistanceCoverageTypes.some((typ) => {
        if (typ === 'aca' && 
            values.privateInsuranceStatusId && values.privateInsuranceStatusId !== privateInsuranceStatuses.find(p => p.picklistTypeName === 'private_insurance_status' && p.itemKey === 'no_benefits')?.id &&
            values.privateInsurancePolicySourceId && values.privateInsurancePolicySourceId === privateInsuranceSources.find(p => p.picklistTypeName === 'private_insurance_source' && p.itemKey === 'aca_exchange')?.id) {
          supportedServices.premiumAssistanceCoverageType = 'aca';
          supportedServices.authorizedAdapPremiumAmount = values.privateInsurancePremiumAmount;
          supportedServices.authorizedAdapPremiumFrequencyId = premiumPaymentFrequencies?.find(f => f.id === values.privateInsurancePremiumPaymentFrequencyId)?.itemName || '';
          supportedServices.authorizedAdapInsuranceStartDate = values.privateInsuranceEffectiveDate;
          supportedServices.authorizedAdapInsuranceCarrier = values.privateInsuranceCarrierName;
          supportedServices.authorizedAdapInsurancePlan = values.privateInsurancePlanName;
          supportedServices.authorizedAdapInsurancePolicySource = privateInsuranceSources.find(p => p.picklistTypeName === 'private_insurance_source' && p.itemKey === 'aca_exchange').itemName;
          return true;
        }
        if (typ === 'employer_spons' && 
            values.privateInsuranceStatusId && values.privateInsuranceStatusId !== privateInsuranceStatuses.find(p => p.picklistTypeName === 'private_insurance_status' && p.itemKey === 'no_benefits')?.id &&
            values.privateInsurancePolicySourceId && values.privateInsurancePolicySourceId === privateInsuranceSources.find(p => p.picklistTypeName === 'private_insurance_source' && p.itemKey === 'employer')?.id) {
          supportedServices.premiumAssistanceCoverageType = 'employer_spons';
          supportedServices.authorizedAdapPremiumAmount = values.privateInsurancePremiumAmount;
          supportedServices.authorizedAdapPremiumFrequencyId = premiumPaymentFrequencies?.find(f => f.id === values.privateInsurancePremiumPaymentFrequencyId)?.itemName || '';
          supportedServices.authorizedAdapInsuranceStartDate = values.privateInsuranceEffectiveDate;
          supportedServices.authorizedAdapInsuranceCarrier = values.privateInsuranceCarrierName;
          supportedServices.authorizedAdapInsurancePlan = values.privateInsurancePlanName;
          supportedServices.authorizedAdapInsurancePolicySource = privateInsuranceSources.find(p => p.picklistTypeName === 'private_insurance_source' && p.itemKey === 'employer').itemName;
          return true;
        }
        if (typ === 'med_part_c' &&
            values.medicareStatusId && values.medicareStatusId !== medicareStatuses.find(p => p.picklistTypeName === 'medicare_status' && p.itemKey === 'no_benefits')?.id
        ) {
          supportedServices.premiumAssistanceCoverageType = 'med_part_c';
          supportedServices.authorizedAdapPremiumAmount = values.medicarePremiumAmount;
          supportedServices.authorizedAdapPremiumFrequencyId = premiumPaymentFrequencies?.find(f => f.id === values.medicarePremiumPaymentFrequencyId)?.itemName || '';
          supportedServices.authorizedAdapInsuranceStartDate = values.medicareEffectiveDate;
          supportedServices.authorizedAdapInsuranceCarrier =  values.medicareCarrierName;
          supportedServices.authorizedAdapInsurancePlan = values.medicarePlanName;
          supportedServices.authorizedAdapInsurancePolicySource = 'Medicare';
          return true;
        }
        return false;
      });

      if (!foundCoveredInsurance) {
        supportedServices.premiumAssistanceEnabled = false;
        supportedServices.premiumAssistanceCoverageType = null;
      }
    }

    const today = moment();
    const todayStr = today.format('MM/DD/YYYY');
    
    const getEffectiveDate = (currentlySpecifiedEffectiveDate, checkInsuranceUpdateEffectiveDate) => {
      let result = currentlySpecifiedEffectiveDate;
      if (!currentlySpecifiedEffectiveDate) {
        result = todayStr;
      }
      if (today > moment(result,'MM/DD/YYYY')) {
        result = todayStr;
      }
      if (!result) {
        result = todayStr;
      }
      if (checkInsuranceUpdateEffectiveDate && moment(result,'MM/DD/YYYY') < moment(values.insuranceUpdateEffectiveDate)) {
        result = values.insuranceUpdateEffectiveDate;
      }
      return result;
    }
  
    if (!values.rwEligible) {
      if (values.areServicesAuthorized !== null) {
        setFieldValue("areServicesAuthorized", null);
      }
      if (values.servicesNotAuthorizedReasonId !== '') {
        setFieldValue("servicesNotAuthorizedReasonId", '');
      }
      setFieldValue("decisionEffectiveDate", null);
    } else {
      setFieldValue("decisionEffectiveDate", values.areServicesAuthorized ? getEffectiveDate(values.decisionEffectiveDate, true) : getEffectiveDate(values.decisionEffectiveDate, false));
    }

    setFieldValue("medicationAssistanceEnabled", values.rwEligible && values.areServicesAuthorized ? supportedServices.medicationAssistanceEnabled : null);
    setFieldValue("medicationAssistanceLevel", values.rwEligible && values.areServicesAuthorized ? supportedServices.medicationAssistanceLevel : null);
    setFieldValue("medicationAssistanceLevelText", values.rwEligible && values.areServicesAuthorized ? supportedServices.medicationAssistanceLevelText : null);
    setFieldValue("premiumAssistanceEnabled", values.rwEligible && values.areServicesAuthorized ? supportedServices.premiumAssistanceEnabled : null);
    setFieldValue("premiumAssistanceCoverageType", values.rwEligible && values.areServicesAuthorized ? supportedServices.premiumAssistanceCoverageType : null);
    setFieldValue("authorizedAdapPremiumAmount", values.rwEligible && values.areServicesAuthorized && supportedServices.authorizedAdapPremiumAmount ? supportedServices.authorizedAdapPremiumAmount : '');
    setFieldValue("authorizedAdapPremiumFrequencyId", values.rwEligible && values.areServicesAuthorized && supportedServices.authorizedAdapPremiumFrequencyId ? supportedServices.authorizedAdapPremiumFrequencyId : '');
    setFieldValue("authorizedAdapInsuranceStartDate", values.rwEligible && values.areServicesAuthorized && supportedServices.authorizedAdapInsuranceStartDate ? supportedServices.authorizedAdapInsuranceStartDate : null);
    setFieldValue("authorizedAdapInsuranceCarrier", values.rwEligible && values.areServicesAuthorized && supportedServices.authorizedAdapInsuranceCarrier ? supportedServices.authorizedAdapInsuranceCarrier : '');
    setFieldValue("authorizedAdapInsurancePlan", values.rwEligible && values.areServicesAuthorized && supportedServices.authorizedAdapInsurancePlan ? supportedServices.authorizedAdapInsurancePlan : '');
    setFieldValue("authorizedAdapInsurancePolicySource", values.rwEligible && values.areServicesAuthorized && supportedServices.authorizedAdapInsurancePolicySource ? supportedServices.authorizedAdapInsurancePolicySource : '');
    
    setTimeout(() => validateForm());
  }

  useEffect(() => {
    setTimeout(() => recalcServices());
  }, [values.rwEligible, values.areServicesAuthorized, values.servicesNeedRecalc, values.wantsToApplyForAssistance, 
    values.subprogramId, values.subprogramIds, values.privateInsurancePolicySourceId, values.privateInsuranceStatusId, values.medicareStatusId]);

  useEffect(() => {
    if (values.servicesNeedRecalc) {
      setTimeout(() => recalcServices());
      setFieldValue('servicesNeedRecalc', false);
    }
  }, [values.servicesNeedRecalc]);

  return values && eligibilityAppConfig && (
    <Stack direction="row">
      <LotusForm>
        {isAdapProcessor && futureAdapEnrollments?.length > 0 &&
        <LotusFormSection>
          {futureAdapEnrollments.map(enr => 
            <Alert variant="filled" severity="info">There is an approved enrollment update for this client that goes into effect {enr.startDate}.</Alert>
          )}
        </LotusFormSection>
        }
        <LotusFormSection name="Ryan White Eligibility" disabled={disabled}>
          <LotusFormItem>
            <EligibilityApplicationRyanWhiteStatus eligibilityAppConfig={eligibilityAppConfig} />
          </LotusFormItem>
        </LotusFormSection>
        <LotusFormSection name={`${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} Enrollment`}>
          <LotusFormItem>
            <LotusPaper>
            {values.rwEligible && !values.isGrandfathered &&
              <>
              {isAdapProcessor && !(isInterimUpdate && !currentAdapEnrollment) &&
                <LotusFormItem>
                  <LotusDatePicker
                    name="decisionEffectiveDate"
                    label="Enrollment Update Effective Date"
                    disabled={disabled || !isAdapProcessor}
                    minDate={moment().format('MM/DD/YYYY')}
                    required
                    dontCheckRequiredFields={true}
                  />
                </LotusFormItem>
              }
              <LotusFormSubSection name="Sub-Program Selection">
                <LotusFormItem>
                  <LotusProgressiveDisclosure
                    name="wantsToApplyForAssistance"
                    label={`Does applicant wish to apply for ${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"}?`}
                    disabled={disabled || (isInterimUpdate && !currentAdapEnrollment)}
                    required
                    dontCheckRequiredFields
                  >
                    <LotusProgressiveDisclosureItem
                      itemValue="true"
                      label="Yes"
                    >
                      {eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && 
                      <LotusCheckboxList
                        disabled={disabled} 
                        name="subprogramIds" 
                        label="Choose Subprogram(s)"
                        items={agencyAdapSubprogramList}
                        required
                        dontCheckRequiredFields
                      />
                      }
                      {!eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && 
                      <LotusRadioGroup
                        disabled={disabled} 
                        name="subprogramId" 
                        label="Choose Subprogram"
                        items={agencyAdapSubprogramList}
                        required
                        dontCheckRequiredFields
                      />
                      }   
                    </LotusProgressiveDisclosureItem>
                    <LotusProgressiveDisclosureItem
                      itemValue='false'
                      label="No" 
                    />
                  </LotusProgressiveDisclosure>
                </LotusFormItem>
              </LotusFormSubSection>
              {values.wantsToApplyForAssistance && isAdapProcessor &&
                <LotusFormSubSection name={`${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} Authorization`}>
                  <LotusFormItem>
                    <LotusProgressiveDisclosure
                      name="areServicesAuthorized"
                      label={`Do you wish to authorize this client for ${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} services?`}
                      required
                      dontCheckRequiredFields={true}
                      disabled={disabled || !isAdapProcessor}
                    >
                      <LotusProgressiveDisclosureItem itemValue='true' label='Yes'>
                        <Stack orientation="column" spacing={3}>
                          {values.medicationAssistanceEnabled &&
                            <Stack orientation="column" spacing={1}>
                              <Stack style={{fontSize: 18, fontWeight: 400}} direction="row" alignContent="center">
                                <CheckCircleOutline color="secondary"/>
                                &nbsp;Medication Assistance
                              </Stack>
                              <LotusTextInput
                                name="medicationAssistanceLevelText"
                                label="Level"
                                disabled={true}
                              />
                            </Stack>
                          }
                          {values.premiumAssistanceEnabled &&
                            <Stack orientation="column" spacing={1}>
                              <Stack style={{fontSize: 18, fontWeight: 400}} direction="row" alignContent="center">
                                <CheckCircleOutline color="secondary"/>
                                &nbsp;Premium Assistance
                              </Stack>
                              <LotusTextInput
                                name="authorizedAdapPremiumAmount"
                                label="Amount"
                                disabled={true}
                                maxLength={16}
                                floatingPoint
                                adornment="$"
                              />   
                              <LotusTextInput
                                name="authorizedAdapPremiumFrequencyId"
                                label="Frequency"
                                disabled={true}
                              />
                              <LotusDatePicker
                                name="authorizedAdapInsuranceStartDate"
                                label="Insurance Start Date"
                                disabled={true}
                              /> 
                              <LotusTextInput
                                name="authorizedAdapInsuranceCarrier"
                                label="Insurance Carrier"
                                disabled={true}
                              />   
                              <LotusTextInput
                                name="authorizedAdapInsurancePlan"
                                label="Insurance Plan"
                                disabled={true}
                              />    
                              <LotusTextInput
                                name="authorizedAdapInsurancePolicySource"
                                label="Policy Source"
                                disabled={true}
                              />
                            </Stack>
                          }
                        </Stack>
                      </LotusProgressiveDisclosureItem>
                      <LotusProgressiveDisclosureItem itemValue='false' label='No'>
                        {adapServiceDenialReasonList &&
                          <LotusSelect
                            name="servicesNotAuthorizedReasonId"
                            label="Reason for Denial"
                            items={adapServiceDenialReasonList}
                            disabled={disabled}
                            required={values.wantsToApplyForAssistance === true && values.areServicesAuthorized === false}
                            dontCheckRequiredFields={true}
                          />
                        }
                      </LotusProgressiveDisclosureItem>      
                    </LotusProgressiveDisclosure>
                  </LotusFormItem>
                </LotusFormSubSection>
              }
              </>
            }
            {!values.rwEligible && !values.isGrandfathered &&
              <LotusFormItem>
                <div>{eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} services cannot be authorized since client is not Ryan White eligible</div>
              </LotusFormItem>
            }
            {values.isGrandfathered &&
              <LotusFormItem>
                <div>{eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} services cannot be authorized for Grandfathered clients.</div>
              </LotusFormItem>
            }
            </LotusPaper>
          </LotusFormItem>
        </LotusFormSection>
        {!values.isGrandfathered && currentAdapEnrollment &&
          <LotusFormSection name={`Current ${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} Enrollment`}>
            <LotusFormItem>
              <LotusPaper>
              <LotusFormItem>
                <LotusTextInput
                  name={`currentAdapEnrollment.subprogramNames`}
                  label="Sub-Program"
                  disabled={true}
                />
              </LotusFormItem>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`currentAdapEnrollment.startDate`}
                      label="Enrollment Effective Start Date"
                      disabled={true}
                    />
                  </LotusFormItem>
                </Grid>
                <Grid item xs={6}>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`currentAdapEnrollment.endDate`}
                      label="Enrollment Effective End Date"
                      disabled={true}
                    />
                  </LotusFormItem>
                </Grid>
              </Grid>
              {currentAdapEnrollment.medicationAssistanceEnabled &&
                <LotusFormSubSection name='Medication Assistance'>
                  <LotusFormItem>
                    <LotusTextInput
                      name={`currentAdapEnrollment.medicationAssistanceLevelText`}
                      label="Level"
                      disabled={true}
                    />
                  </LotusFormItem>
                </LotusFormSubSection>
              }
              {currentAdapEnrollment.premiumAssistanceEnabled &&
                <LotusFormSubSection name='Premium Assistance'>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <LotusFormItems>
                        <LotusDatePicker
                          name={`currentAdapEnrollment.authorizedAdapInsuranceStartDate`}
                          label="Insurance Start Date"
                          disabled={true}
                        /> 
                        <LotusTextInput
                          name={`currentAdapEnrollment.authorizedAdapPremiumAmount`}
                          label="Amount"
                          disabled={true}
                          maxLength={16}
                          floatingPoint
                          adornment="$"
                        />   
                        <LotusTextInput
                          name={`currentAdapEnrollment.authorizedAdapPremiumFrequencyName`}
                          label="Frequency"
                          disabled={true}
                        />
                      </LotusFormItems>
                    </Grid>
                    <Grid item xs={6}>
                      <LotusFormItems>
                        <LotusTextInput
                          name={`currentAdapEnrollment.authorizedAdapInsurancePolicySource`}
                          label="Policy Source"
                          disabled={true}
                        />
                        <LotusTextInput
                          name={`currentAdapEnrollment.authorizedAdapInsuranceCarrier`}
                          label="Insurance Carrier"
                          disabled={true}
                        />   
                        <LotusTextInput
                          name={`currentAdapEnrollment.authorizedAdapInsurancePlan`}
                          label="Insurance Plan"
                          disabled={true}
                        />    
                      </LotusFormItems>
                    </Grid>
                  </Grid>
                </LotusFormSubSection>
              }
              </LotusPaper>
            </LotusFormItem>
          </LotusFormSection>
        }
        <LotusSpacedBlock/>
      </LotusForm>
      <div>
        <SectionCommentsChain chainFieldName="subprogramCommentsChain"/>
      </div>
    </Stack>
  );
};

export function buildServicesStaticForm({...props}) {
  return <EligibilityApplicationServicesStaticForm {...props}/>
}