import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useAgencyIntegrations } from 'contexts/AgencyIntegrationsContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import Typography from '@mui/material/Typography';
import Cron from 'react-cron-generator'
import { HEADER } from 'react-cron-generator';
import './cron-builder-css-override.css'
const map = require('lodash/map');
const cronstrue = require('cronstrue');
const scheduleNameRegex = '^[0-9a-zA-Z\-]{1,64}$';
const subdirectoryRegex = '^[0-9a-zA-Z-_\/]{1,255}$';

const options = {
  headers: [HEADER.DAILY, HEADER.HOURLY, HEADER.MINUTES]
};
const INTEGRATION_TYPES = [{ name: 'Export to CAREWare (Enrolled Clients)', integrationType: 'careware_export_all' },
{ name: 'Export to CAREWare (Recently Updated Clients)', integrationType: 'careware_export_recent' },
{ name: 'Export to PBM', integrationType: 'pbm_export' }];

const validationSchema = yup.object({
  name: yup.string().required('Integration Name is required').matches(scheduleNameRegex, {
    message: 'Only letters, numbers and hyphen (-) are allowed. Max 64 characters.',
    excludeEmptyString: true,
  }).test({
    message: () => 'Name must be unique',
    test(value) {
      return this.parent.names.indexOf(value) === -1;
    },
  }),
  subdirectory: yup.string().matches(subdirectoryRegex, {
    message: 'Only letters, numbers, forward slash (/), hyphen (-), and underscore (_) are allowed. Max 255 characters.',
    excludeEmptyString: true,
  }),
});

export default function AgencyIntegrationForm({
  agencyIntegration,
  integrations,
  agencyId,
  handleSubmit,
  readOnly,
}) {

  const { connectors, loadAgencyConnectors, loadAgencyIntegrationDetailsSync } = useAgencyIntegrations();
  const [scheduleText, setScheduleText] = useState();
  const [schedule, setSchedule] = useState();
  const [initialValues, setInitialValues] = useState();

  const handleScheduleChange = (e) => {
    let txt = cronstrue.toString(e);
    if (txt.includes('AM') || txt.includes('PM')) {
      txt = txt + ' Eastern';
    }
    setSchedule(e);
    setScheduleText(`Integration will trigger: ${txt}`);
  }

  useEffect(() => {
    const loadDetails = async () => {
      const integration = await loadAgencyIntegrationDetailsSync(agencyId, agencyIntegration.name);

      setInitialValues({
        name: agencyIntegration.name,
        integrationType: integration.integrationType,
        ConnectorId: integration.connectorId,
        arn: integration.arn,
        names: [],
        integrationParams: integration.integrationParams || {},
        subdirectory: integration.subdirectory
      })
      setSchedule(integration.schedule);
      let txt = 'Integration will trigger: ' + cronstrue.toString(integration.schedule);
      setScheduleText(txt);

    }
    if (agencyIntegration) {
      loadDetails();
    } else {
      const names = map(integrations, 'name');
      setInitialValues({
        name: '',
        integrationType: '',
        ConnectorId: '',
        names: names,
        integrationParams: {}
      });
    }
  }, []);

  useEffect(() => {
    if (agencyId) {
      loadAgencyConnectors(agencyId);
    }
  }, [agencyId]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await handleSubmit({ ...values, schedule: schedule });
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const reinitializeParams = (integrationTyp) => {
          const params = {}
          if (integrationTyp === 'careware_export_recent') {
            params.numberOfDays = '14';
          }
          setFieldValue("integrationParams", params);
        }
        return values && (
          <Form onSubmit={handleSubmit}>
            <LotusForm>
              <LotusFormItem>
                <LotusTextInput
                  name="name"
                  label="Integration Name"
                  disabled={readOnly || values?.arn}
                  required
                />
              </LotusFormItem>
              {values && (
                <LotusFormItem>
                  <LotusSelect
                    label="Integration Type"
                    name="integrationType"
                    items={INTEGRATION_TYPES.map(atype => { return { label: atype.name, value: atype.integrationType } })}
                    disabled={readOnly}
                    required
                    handleChange={(val) => setTimeout(() => reinitializeParams(val))}
                  />
                </LotusFormItem>
              )}
              {values && values.integrationType === 'careware_export_recent' && values.integrationParams.numberOfDays !== undefined && (
                <LotusFormItem>
                  <LotusTextInput
                    name="integrationParams.numberOfDays"
                    label="Number of Days"
                    disabled={readOnly}
                    required
                    integer
                  />
                </LotusFormItem>
              )}
              {values && connectors && (
                <LotusFormItem>
                  <LotusSelect
                    label="External Servers"
                    name="ConnectorId"
                    items={connectors.map(connector => { return { label: connector.Url, value: connector.ConnectorId } })}
                    disabled={readOnly}
                    required
                  />
                </LotusFormItem>
              )}

              <LotusFormItem>
                <LotusTextInput
                  name="subdirectory"
                  label="Remote Server Subdirectory"
                  disabled={readOnly}
                />
              </LotusFormItem>

              <Typography variant="h4" component="h4">
                Schedule
              </Typography>
              <LotusFormItem>
                <Cron
                  label="Schedule"
                  name="schedule"
                  onChange={(e, t) => { handleScheduleChange(e, t); }}
                  value={schedule}
                  options={options}
                  disabled={readOnly}

                />
              </LotusFormItem>
              <LotusFormItem>
                <LotusFormItem>
                  <Typography variant="body" >{scheduleText}</Typography>
                </LotusFormItem>
              </LotusFormItem>
              {!readOnly && (
                <LotusFormItem>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              )}
            </LotusForm>
          </Form>
        );
      }}
    </Formik>
  );
}
